import React from "react"
import Layout from "src/component/layout/layout"
import { Seo } from "src/component/common/seo"
import { Link } from "gatsby"

export default function Privacy() {
    return (
        <>
            <Layout>
                <div className="p-privacy">
                <div className="p-privacy__inner c-inner-primary">
                    <div className="l-oldContent">
                        <div className="c-title01">
                            <h1 className="p-contact__title__text c-title01__text is-center">お問い合わせが完了しました</h1>
                        </div>
                        <div className="l-oldContent__inner">
                            <div className="c-thanks">
                                <p>お問い合わせいただきありがとうございました。<br />お問い合わせを受け付けました。</p>
                                <div className="c-btn-entry">
                                    <Link to={"/"}>ページトップへ戻る</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </Layout>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="お問い合わせが完了しました"
            description=""
            pathname=""
            isNoindex
        />
    )
}